const config = {
  siteTitle: 'Florian Hübler',
  siteTitleShort: 'Florian Hübler',
  siteTitleAlt: 'Florian Hübler',
  siteLogo: '/logos/logo-1024.png',
  siteUrl: 'https://www.florianhuebler.com',
  repo: '',
  pathPrefix: '',
  dateFromFormat: 'YYYY-MM-DD',
  dateFormat: 'MMMM Do, YYYY',
  siteDescription:
    'Florian Hübler is a simulation modeler who wants to share AnyLogic insights.',
  siteRss: '/rss.xml',
  //googleAnalyticsID: 'UA-139361696-1',
  googleAnalyticsID: '',
  disqusShortname: '',
  postDefaultCategoryID: 'Tech',
  userName: 'Florian',
  userEmail: 'huebler@posteo.net',
  userTwitter: '',
  userLocation: 'Regensburg,GER',
  userAvatar: '',
  userDescription:
    'I develop simulation models.',
  menuLinks: [
    {
      name: 'About me',
      link: '/me/',
    },
    {
      name: 'Blog',
      link: '/blog/',
    },
/*     {
      name: 'Contact',
      link: '/contact/',
    }, */
  ],
  themeColor: '#3F80FF', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff',
}

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = ''
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/') config.siteUrl = config.siteUrl.slice(0, -1)

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== '/') config.siteRss = `/${config.siteRss}`

module.exports = config
